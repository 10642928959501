import Loader from 'components/Loader'
import { Link } from 'gatsby'
import { useEffect, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

const ErrorNotice = ({ children }) => (
  <section className="section">
    <p>{children}</p>
  </section>
)

const Validate = () => {
  const [key] = useQueryParam('key', StringParam)
  const [data, setData] = useState(null)
  const [fetchError, setFetchError] = useState(false)
  const [loading, setLoading] = useState(true)

  const APICall = async () => {
    try {
      let response = await fetch('/.netlify/functions/checkKey', {
        method: 'POST',
        body: JSON.stringify({ key: key }),
      })
      if (!response.ok) {
        throw new Error('Unable to verify key.')
      } else {
        response = await response.json()
        setData(response)
        setLoading(false)
      }
    } catch (err) {
      setFetchError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    APICall()
  }, [])

  if (!key) return <ErrorNotice>No key specified</ErrorNotice>
  if (loading) return <Loader />
  if (fetchError)
    return (
      <ErrorNotice>
        Unable to validate certificate key. If you think this is an error, please{' '}
        <Link to="/contact">contact our support team</Link>.
      </ErrorNotice>
    )

  return (
    <section className="section">
      <div className="container">
        <h1 className="title has-text-midnightBlue is-size-4 mobile">Coronavirus Recovery Certificate Validation</h1>

        {data.issued && (
          <>
            <h2 className="subtitle mt-2 has-text-armyGreen">Valid Coronavirus Recovery Certificate</h2>
            <p>
              <b>Certificate Reference:</b> {data.id}
            </p>
            <p>
              <b>Certificate Issue Date:</b> {data.issued}
            </p>
            <p>
              <b>Name:</b> {data.fname + ' ' + data.lname}
            </p>
            <p>
              <b>Date of Birth:</b> {data.dob}
            </p>
          </>
        )}
        {!data.issued && (
          <>
            <h2 className="subtitle mt-2 has-text-danger">Not a valid recovery certificate</h2>
          </>
        )}
      </div>
    </section>
  )
}

export default Validate
